import React, { useState, useEffect } from 'react';
import { parseUnits, formatEther } from 'ethers/lib/utils';
import PRESALE_ABI from "../ABI/Presale_ABI.json";
import { Contract } from 'ethers';
import NFTVERIFICATION_ABI from "../ABI/NFTVerification_ABI.json";
import { useContractWrite, useAccount } from 'wagmi';
import TransactionModal from './TransactionModal';
import { CONTRACT_ADDRESS_PRESALE, CONTRACT_ADDRESS_NFTVERIFICATION } from './Constants';
import Balances from './Balances';
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import "../CSS/App.css"; 
import castleLogo from '../Assets/castle-logo.png';
import bnbLogo from '../Assets/bnb-logo.png';
import CastleABI from "../ABI/Castle_ABI.json";
import { CONTRACT_ADDRESS_CASTLE } from './Constants';

function toWei(etherString) {
  return parseUnits(etherString, 18);
}

const TokenInput = ({ logo, tokenSymbol, balance, amount, onAmountChange }) => (
  <div className="token-input">
    <div className="token-details">
      <img src={logo} alt={`${tokenSymbol} logo`} className="token-logo" />
      <span>{tokenSymbol}</span>
      <span className="balance">{balance && `Balance: ${balance}`}</span>
    </div>
    <input
      type="text"
      placeholder={`0.0`}
      value={amount}
      onChange={(e) => onAmountChange(e.target.value)}
      className="input-box"
    />
  </div>
);

function getLocalDateTime(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000);
  const options = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit',
    timeZoneName: 'short', hour12: false
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

function Presale() {
  const { address } = useAccount();
  const [bnbAmount, setBnbAmount] = useState("");
  const [castleAmount, setCastleAmount] = useState("");
  const [userPrice, setUserPrice] = useState(0.000225); // Default price
  const [userBnbBalance, setUserBnbBalance] = useState("0");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [txHash, setTxHash] = useState(null);
  const [transactionState, setTransactionState] = useState('INITIAL'); // Possible values: INITIAL, WAITING_CONFIRMATION, SUBMITTED, FAILED, COMPLETED
  const { primaryWallet } = useDynamicContext();
  const [refreshBalanceKey, setRefreshBalanceKey] = useState(0);
  const PRESALE_END_TIMESTAMP = 1711598400; // Replace with your timestamp
  const [countdown, setCountdown] = useState("");
  const [selectedPercentage, setSelectedPercentage] = useState(null);
  const [castleBalance, setCastleBalance] = useState('0');

useEffect(() => {
  const interval = setInterval(() => {
    const now = new Date().getTime();
    const distance = (PRESALE_END_TIMESTAMP * 1000) - now;

    if (distance < 0) {
      clearInterval(interval);
      setCountdown("PRESALE ENDED");
      return;
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
  }, 1000);

  return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  useEffect(() => {
    const checkNFTOwnership = async () => {
       if (!address || !primaryWallet) {
            alert("Please connect your wallet");
            return;
        }

        try {
        const provider = await primaryWallet.connector.ethers?.getWeb3Provider();
        
        if (!provider) {
                throw new Error("No provider");
            }
          const NFTVerificationContract = new Contract(CONTRACT_ADDRESS_NFTVERIFICATION, NFTVERIFICATION_ABI, provider);

          const hasNFT = await NFTVerificationContract.hasApprovedNFT(address);
          setUserPrice(hasNFT ? 0.0002 : 0.000225);
        } catch (error) {
          console.error("Error checking NFT ownership:", error);
          // Handle the error appropriately
        }
      };

    checkNFTOwnership();
  }, [address, primaryWallet]);


  const { isLoading, isSuccess, data, write } = useContractWrite({
    address: CONTRACT_ADDRESS_PRESALE,
    abi: PRESALE_ABI,
    functionName: 'buyTokens',
  });

  function buyTokens(etherAmountAsString) {
    const amountInWei = toWei(etherAmountAsString).toString();
    setIsModalOpen(true);
    setTransactionState('WAITING_CONFIRMATION');
    
    write({ value: amountInWei });
  }

 useEffect(() => {
    if (address) {
        const fetchBalance = async () => {
            const provider = await primaryWallet.connector.ethers?.getWeb3Provider();

            if (!provider) {
              throw new Error("No provider")
            }
            const balance = await provider.getBalance(address);
            setUserBnbBalance(Number(formatEther(balance)).toFixed(6));
        };

        fetchBalance();
    }
}, [address, primaryWallet]);

useEffect(() => {
    const fetchBalances = async () => {
      if (!address || !primaryWallet) {
        alert("Please connect your wallet");
        return;
      }

      try {
        const provider = await primaryWallet.connector.ethers?.getWeb3Provider();
        if (!provider) {
          throw new Error("No provider");
        }
        const castleContract = new Contract(CONTRACT_ADDRESS_CASTLE, CastleABI, provider);

        // Fetching Castle balance and format it
        const castle = await castleContract.balanceOf(address);
        const castleFormatted = formatEther(castle);
        setCastleBalance(Number(castleFormatted).toFixed(6)); // Updated formatting
      } catch (error) {
        console.error("Failed to fetch Castle balance:", error);
      }
    };

    fetchBalances();
  }, [address, primaryWallet]);

  useEffect(() => {
  function startTransactionConfirmationListener(txHash) {
  const interval = setInterval(async () => {
    try {
      const provider = await primaryWallet.connector.ethers?.getWeb3Provider();
      const receipt = await provider.getTransactionReceipt(txHash);
      console.log('Transaction Receipt:', receipt); // Log the receipt for debugging

      if (receipt) {
        if (receipt.status === 1 || receipt.confirmations > 0) {
          clearInterval(interval);
          setTransactionState('COMPLETED');
          setRefreshBalanceKey(prevKey => prevKey + 1);
        } else if (receipt.status === 0) {
          clearInterval(interval);
          setTransactionState('FAILED');
        }
      }
    } catch (error) {
      console.error('Error fetching transaction receipt:', error);
      clearInterval(interval);
      setTransactionState('FAILED');
    }
  }, 3000); // Poll every 3 seconds
}


  if (isSuccess && data && data.hash) {
    setTxHash(data.hash);
    setTransactionState('SUBMITTED');
    startTransactionConfirmationListener(data.hash);
  } else if (!isLoading && !isSuccess) {
    setTransactionState('FAILED');
  }
}, [isLoading, isSuccess, data, primaryWallet.connector.ethers]);

  const handleBnbChange = (value) => {
    setBnbAmount(value);
    setCastleAmount((value / userPrice).toFixed(6)); // Adjust calculation based on user price
  };

  const handleCastleChange = (value) => {
    setCastleAmount(value);
    setBnbAmount((value * userPrice).toFixed(6)); // Adjust calculation based on user price
  };

  const canAfford = parseFloat(bnbAmount) <= parseFloat(userBnbBalance);

  const isValueEntered = bnbAmount !== "" || castleAmount !== "";

function getTransactionMessage() {
    switch (transactionState) {
      case 'INITIAL':
        return '';
      case 'WAITING_CONFIRMATION':
        return 'Confirm transaction in your wallet';
      case 'SUBMITTED':
        return `Transaction submitted. Waiting for completion.`;
      case 'FAILED':
        return 'Transaction failed. Please try again.';
      case 'COMPLETED':
        return 'Transaction completed successfully.';
      default:
        return '';
    }
  }

  const selectPercentage = (percentage) => {
  let newAmount;
  
  if (percentage === 1) { // When the MAX button is clicked
    const maxAmount = parseFloat(userBnbBalance) - 0.01; // Subtract 0.01 BNB to cover gas fees
    newAmount = maxAmount > 0 ? maxAmount.toFixed(6) : '0'; // Make sure it doesn't go negative
  } else {
    newAmount = (parseFloat(userBnbBalance) * percentage).toFixed(6);
  }
  
  handleBnbChange(newAmount);
  setSelectedPercentage(percentage);
};


return (
    <div className="presale">
      <div className="countdown"><h1>Presale Ends In: <br></br>{countdown}</h1>
      <h2>{getLocalDateTime(PRESALE_END_TIMESTAMP)}</h2>
      <h2>Castle Token: <a href="https://bscscan.com/token/0x3946a5f0c0ad124e58e3079a59377e629def4add" target="_blank" rel="noopener noreferrer">
      0x3946A5f0c0aD124e58e3079A59377E629deF4add
    </a></h2></div>
      <h1 className="pricing">Public Presale Price: 1 Castle = 0.000225 BNB<br></br><br></br>
               Amulet Presale Price: 1 Castle = 0.000200 BNB</h1>
      <section className="castle-section">
        <div className="token-input-section">
          <TokenInput
            logo={bnbLogo}
            tokenSymbol="BNB"
            balance={userBnbBalance}
            amount={bnbAmount}
            onAmountChange={handleBnbChange}
          />
          <div className="percentage-buttons">
            {['25', '50', '75', 'MAX'].map((percent) => (
              <button
                key={percent}
                onClick={() => selectPercentage(percent === 'MAX' ? 1 : parseFloat(percent) / 100)}
                className={`percentage-button${selectedPercentage === (percent === 'MAX' ? 1 : parseFloat(percent) / 100) ? ' selected' : ''}`}
              >
              {percent !== 'MAX' ? `${percent}%` : percent} {/* Here's the change */}
              </button>
              ))}
          </div>
        </div>
        <div className="token-input-section">
          <TokenInput
            logo={castleLogo}
            tokenSymbol="Castle"
            balance={castleBalance}
            amount={castleAmount}
            onAmountChange={handleCastleChange}
          />
        </div>
        <p><b>Your price: {userPrice} BNB per Castle Token</b></p>
        <button
          onClick={() => buyTokens(bnbAmount)}
          disabled={!canAfford || !isValueEntered}
          className="presale-button"
        >
          Buy Castle
        </button>
        {!canAfford && isValueEntered && <p className="insufficient-funds">You do not have enough BNB for this transaction.</p>}
      </section>
      <TransactionModal
        isOpen={isModalOpen}
        status={getTransactionMessage()}
        txHash={txHash}
        onClose={() => setIsModalOpen(false)}
      />
      <div>
        <Balances key={refreshBalanceKey} />
      </div>
      <div>
      <Balances bnbBalance={userBnbBalance} castleBalance={castleBalance} />
    </div>
    </div>
    
  );
}

export default Presale;