import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi'; // Import useAccount hook
import Presale from './Presale';

function MainInterface() {
    const navigate = useNavigate();
    const { isConnected } = useAccount(); // Use useAccount hook to check connection

    const handlePresaleClick = () => {
        if (!isConnected) {
            alert("Please connect your wallet before continuing");
        } else {
            navigate("/Presale"); // Navigate to Presale if wallet is connected
        }
    };

    return (
        <div>
            <div className="nav-wrapper">
                <nav className="navigation">
                    <button onClick={handlePresaleClick} className="nav-button">
                        BUY CASTLE PRESALE
                    </button>
                </nav>
            </div>
            <div className="main-content">
                <Routes>
                    <Route path="/Presale" element={<Presale />} />
                </Routes>
            </div>
        </div>
    );
}

export default MainInterface;
