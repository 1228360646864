import React from 'react';
import "../CSS/App.css";

function Balances({ bnbBalance, castleBalance }) {
   
    return (
    <div className="balance-container">
      <div className="balance-item">
        <strong>BNB:</strong> {bnbBalance}
      </div>
      <div className="balance-item">
        <strong>Castle:</strong> {castleBalance}
      </div>
    </div>
  );
}

export default Balances;